<template>
    <v-list-item
        class='msp-chat'
        @click="setChat(commento.chat)"
    >
        <v-badge
            :content="nuovi"
            :offset-x='25'
            :offset-y='25'
            :value='nuovi'
            color="green"
            bottom
            overlap >
            <msp-avatar v-if="commento.utente" :utente="commento.utente"></msp-avatar>
        </v-badge>

        <v-list-item-content>

            <v-list-item-title class='titolo'>
                <span class='nome' v-if="commento.utente">
                    {{commento.utente.nome}} {{commento.utente.cognome}}
                </span>
            </v-list-item-title>
            <span class='sport'><i :class="iconaSport"></i></span>
            <template v-if="commento.chat">
                <div class="ultimo" v-text="commento.chat.testo"> </div>
                <div class="inviato" >{{shortDate(commento.chat.messaggio.utc_data_inviato)}}</div>
            </template>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import env from '@mixins/environment.js'
import funzioni from '../../../js/msp/funzioni.js'
import MspAvatar from "./MspAvatar.vue"
    const component = {
        components: {
            MspAvatar,
        },
        props: [
            'commento'
        ],
        computed: {
            iconaSport: function () {
                if (!funzioni.deepProp(this.commento,'chat.chat.allenamento')) return "";
                let sportId = this.commento.chat.chat.allenamento.sport_id;
                return "msp-icon-sport-"+sportId;
            },
            nuovi: function () {
                let nuovi = funzioni.deepProp(this.commento,'chat.nuovi.length');
                if (!nuovi) return 0;
                return nuovi;
            },
        },
        data() {
            return {
            }
        },
        mounted: function () {
            this.init();
        },
        mixins: [
            env
        ],
        methods: {
            shortDate: function (value) {
                if (!value) return ''
                let shortDate;
                let date = new Date(value);
                let now = new Date();
                if (now.toDateString() === date.toDateString()) {
                    shortDate = date.toLocaleTimeString();
                } else {
                    shortDate = date.toLocaleDateString();
                }

                return shortDate;
            },
            init: function () {},
            initials: function (utente) {
                let output = "";
                if (utente.nome) {
                    output += utente.nome.substr(0,1);
                }
                if (utente.cognome) {
                    output += utente.cognome.substr(0,1);
                }
                return output;
            },
            setChat: function (chat) {
                this.$emit('select', chat);
            }
        }
    }
    export default component;
</script>

<style lang="scss">
.msp-chat {
    --col-avatar: var(--col-grigio-chiaro);
    --size-avatar: 100%;
    border-bottom: 3px solid var(--col-grigio-chiaro);
    .v-list-item__content {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto auto;
        grid-template-areas: "titolo sport" "ultimo sport" "inviato inviato";
        align-items: start;
    }
    .sport {
        font-size: 40px;
        grid-area: sport;
        display: grid;
    }
    .nome {
        font-weight: bold;
    }
    .ultimo {
        grid-area: ultimo;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titolo {
        grid-area: titolo;
    }
    .inviato {
        grid-area: inviato;
        text-align: right;
        font-style: italic;
        font-size: 0.8em;
    }
    }
</style>
