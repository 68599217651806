<template >
    <div class="chats">

        <msp-app-bar title="Messaggi"></msp-app-bar>

        <div class="chats-container" >
             <v-text-field
            filled
            solo
            hide-details
            v-model="cerca"
            label="Cerca per nome..."
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
            <div ref = "lista" class="chats-lista">

                <contatto
                    v-for="contatto in filteredContatti"
                    :key="contatto.utente.id"
                    :contatto="contatto"
                    @select="openChat(contatto)"
                />
            </div>

        </div>
        <v-dialog
            v-model="showModal"
            fullscreen
            hide-overlay
            transition="slide-x-transition"
            :return-value="'detail'"
        >
            <router-view></router-view>
        </v-dialog>
    </div>
</template>


<script type="module">
import {  mapState } from 'vuex'
import mspAppBar from '@components/MspAppBar.vue';
import api from '@mixins/api.js';
import _messaggi from '@mixins/messaggi.js';
import env from '@mixins/environment.js'
import funzioni from '@src/js/msp/funzioni.js'
import contatto from '@components/Contatto.vue'
const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
}
const locale = "it-IT";
const INTL = new Intl.DateTimeFormat(locale, options);


const component = {
    mixins: [
    env,
    ],
    components: {
        mspAppBar,
        contatto,
    },
    props: [
    ],
    computed: {
    ...mapState(
      {
        dialog: (state)=>state.messaggio.dialog,
      }
    ),

      filteredContatti () {
        let contatti = funzioni.filter(this.contatti, this.cerca, ['utente.nome', 'utente.cognome']);
        return contatti;
      }
    },

  watch: {
      dialog: function (val) {
          this.showModal = val;
      },

    $route: {
      immediate: true,
      handler: function(newVal) {
        this.showModal = newVal.meta && newVal.meta.showModal;
      }
    }

  },
    data() {
        return {
            cerca: "",
            showModal: false,
            timeOffset: 0,
            sending: false,
            watcher: null,
            chat: "",
            contatti: [],
        }
    },

    beforeDestroy: function () {
        clearInterval(this.watcher);
        this.watcher = null;
    },

    mounted() {
        this.init();
    },

    methods: {
        init: async function () {
            this.timeOffset = this.getTimezoneOffset();
            await this.loadChats();
            this.watchChats();
        },
        openChat: async function (contatto) {
            const utenteId = contatto.utente.id;
            const newChat = await _messaggi.addChat('one_on_one', utenteId);
            if (!newChat) return;
            const chatId = newChat.chat_id;
            this.$store.dispatch("messaggio/setDialog",true);
            this.$router.push("/chats/"+chatId);
        },

        makeChatsListItem: function (contatto) {
            if (!(contatto.chat)) return null;
            let testo = (contatto.chat.ultimo) ? this.makeExcerpt(contatto.chat.ultimo.testo) : null;
            return {
                ...contatto.chat,
                testo: testo,
            }
        },
        makeChatsList: function (chats) {
            if (!(chats && chats.length)) return null;

            let contatti = chats.map((el)=>{
                let chat = this.makeChatsListItem(el);
                return {
                    utente: el.utente,
                    chat: chat,
                }
            });
            contatti = contatti.sort((a,b)=>{
                // TODO: ordinamento alfabetico 
                if (!(a.chat && a.chat.ultimo)) return 1;
                if (!(b.chat && b.chat.ultimo)) return -1;
                return (a.chat.ultimo.utc_data_inviato < b.chat.ultimo.utc_data_inviato ? 1 : -1);
            });

            return contatti;
        },

        getChat: function (id) {
            return this.chats.find(el=>el.id==id);
        },

        getTimezoneOffset: function () {
            const d = new Date();
            return d.getTimezoneOffset() * 60 * 1000; // minuti di differenza dell'UTC
        },

        setLetti: function (a_ids) {
            a_ids.forEach(this.setLetto);
        },

        setLetto: function (id) {
            let chat = this.getChat(id);
            if(!chat) return;
            if (chat.utc_data_letto) return;  
            if (this.letti.indexOf(id) === -1) {
                this.letti.push(+id);
            }
        },

        clearLetti: function (letti) {
            if (!(letti && letti.length)) return;
            letti = letti.map(id=>+id);
            const difference = this.letti.filter((el)=>{
                return letti.indexOf(el) === -1
            });
            this.letti = [...difference];
        },

        updateLetti: async function () {
            if (!(this.letti && this.letti.length)) return Promise.resolve({success: false});
            let a_ids = [...this.letti];
            let ids = a_ids.join(",");
            this.clearLetti(ids.split(","));

           _messaggi.setLetti(a_ids);
        },



        giorno: function (dateString) {
            let date = new Date(dateString);
            return INTL.format(date);
        },

        getDayChat: function (chat) {
            let date = new Date(chat.timestamp_inviato*1000);
            return date.toDateString();
        },

        newDay: function (index) {
            if (index === 0) return true;
            let date1 = this.getDayChat(this.chats[index]);
            let date2 = this.getDayChat(this.chats[index - 1]);
            return date1 !== date2;
        },

        clearChat: function () {
            this.chat = "";
        },

        setAllChatsRead: function () {
            let ids = this.chats && this.chats.map((el)=>(el.id));
            this.setLetti(ids);
            this.updateLetti();
        },


        send: async function () {
            this.sending = true;
            if (!(this.chat && this.chat.length)) {
                return;
            }
            let url = this.api_base_url+"/api/v2/chat.php";
            let data = {
                "testo": this.chat,
            };
            const result = await api(url, 'POST', data);
            this.sending = false;
            if (result.success) {
                this.clearChat();
                await this.loadChats();
            }
        },

        debounceUpdate: function () {
            funzioni.debounce(this.update,500)()
        },

        update: function () {
            this.$emit('update', this.index, {...this.dettaglio });
        },


        watchChats: function () {
            // TODO attivare watcher solo se riesco a disattivarlo una volta uscito dalla pagina chats
            this.watcher = setInterval(this.loadChats, 10000); 
        },


        filterMilliseconds: function (ms) {
            let offset = this.timeOffset;
            if (!ms) return null;
            return ms - offset;
        },

        getMilliseconds: function (date) {
            return new Date(date).valueOf();
        },

        setLocaleDate: function (utc_date) {
            if (!utc_date) return null;
            const ms = this.filterMilliseconds(this.getMilliseconds(utc_date));
            return new Date(ms);
        },


          htmlDecode: function (input) {
              var doc = new DOMParser().parseFromString(input, "text/html");
              return doc.documentElement.textContent;
          },

          makeExcerpt: function (text) {
              if (!(text && text.length)) return "";
              let excerpt = text.replace(/<\/?[^>]+(>|$)/g, "");
              excerpt = this.htmlDecode(excerpt);
              return excerpt;
          },

          loadChats: async function () {
              const result = await _messaggi.loadChatsOneOnOne();
              if (result.success) {
                  this.contatti = this.makeChatsList(result.data);
              } else {
                  this.contatti = [];
              }
              return Promise.resolve(result);
          },
    }
}
    export default component;
</script>

<style lang="scss">
.chats {
    --z-top: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    .chats-buttons {
        position: absolute;
        z-index: 1;
        top: 3px;
        right: 10px;
    }

    .chats-container {
        overflow: hidden;
        height: 100%;
        grid-template-columns: 1fr auto;
    }
    .chats-lista {
        overflow: auto;
        padding: 10px;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .chats-footer {
        flex-basis: 125px;
        flex-grow: 0;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
    }
    .to-bottom {
        z-index: var(--z-top);
        position: absolute;
        top: 0;
        margin-top: -10px;
        transform: translatey(-100%);
        right: 20px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
    .chat-data {
        z-index: var(--z-top);
        position: sticky;
        background: var(--col-msp);
        color: #fff;
        padding: 2px 10px;
        font-size: 0.8em;
        margin: 0 auto;
        text-align: center;
        display: inline-block;
        top: 0px;
        border-radius: 5px;
        pointer-event: none;
    }
}
</style>
