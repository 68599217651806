<template>
    <div class="messaggi">

        <v-app-bar app dark  :color="colorAppBar">
            <v-btn icon @click="dismissModal()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-toolbar-title>
                <div class="test-titolo">
                    {{nomeChat}}
                </div>
            </v-toolbar-title>

        </v-app-bar>

        <div class="messaggi-container" >
            <div class="messaggi-container__header" v-if="hasEvento"> 
 
               
            <i class='icon-sport' :class="iconSport"></i>    <span class="header-data">{{formatDate(chat.chat.evento.data)}}</span> <span v-if="nomeChatCalendario" class="header-titolo">{{nomeChatCalendario}}</span>
         
        </div>

            <lista-messaggi
                :chat="chat"
                :messaggi="messages"
                :refresh="lista.refresh"
            />
            <div class="messaggi-footer">
                <div v-if="editorSettings" class="messaggi-textarea">
                    <editor
                        @update="update()"
                        v-model="messaggio"
                        :mode="editorSettings.mode"
                    />
                </div>
                <div class="messaggi-buttons">
                    <v-btn 
                        color="primary"
                        fab
                        x-small
                        class="button-send"
                        v-show="messaggio && !sending"
                        @click="send"><v-icon>mdi-send</v-icon></v-btn>
                </div>
            </div>
        </div>



    </div>
</template>

<script type="module">
import messaggi from "@mixins/messaggi.js"
import funzioni from "@src/js/msp/funzioni.js"
import ListaMessaggi from "@components/ListaMessaggi.vue"
import editor from "@src/js/vue/include/Editor.vue";
import { mapGetters } from 'vuex'

const updateEditorSettings =  function updateEditorSettings(type) {
    if (!_editorSettings[type]) return;
    editorSettings = {
        ..._editorSettings.commons,
        ..._editorSettings[type],
    };
}

let _editorSettings = {
    commons: {
    },
    one_one_one: {
        mode: 'empty',
    },
    calendario: {
        mode: 'empty',
    }
};

let editorSettings = { };

const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
}
const locale = "it-IT";
const INTL = new Intl.DateTimeFormat(locale, options);


const component = {
    mixins: [
    ],
    components: {
        ListaMessaggi,
        editor,

    },
    props: [
    ],
    data() {
        return {
            lista: {
                refresh: 0,
            },
            editorSettings: null,
            timeOffset: 0,
            sending: false,
            watcher: null,
            nuovi: null,
            chat: null,
            chatId: 0,
            messaggio: "",
            messages: [],
        }
    },
    computed: {
    ...mapGetters('calendario', [
      'events',
      'commenti',

    ]),
        iconSport: function () {
            if (!this.hasEvento) return "";
            return "msp-icon-sport-"+this.chat.chat.evento.sport_id;
        },
    colorAppBar: function () {
    },

        me: function () {
            let me = funzioni.deepProp(this.chat,'me');
            return me;
        },
        utentiTranneMe: function () {
            let utenti = funzioni.deepProp(this.chat,'utenti');
            if (!utenti) return [];
            let mioId = this.me;
            return utenti.filter((ut)=>ut.id != mioId);
        },
        nomeChat: function () {
            if (!this.chat){
                return "";
            }
            const nomi = this.utentiTranneMe.map((utente)=>{
                return `${utente.nome} ${utente.cognome}`;
            })

            return nomi.join(", ");
        },

        nomeChatCalendario: function () {
            if (!this.chat) return false;
            if (this.chat.type !== 'calendario') {
                return false;
            }
            const evento = this.chat.chat.evento;
            const nome = evento.titolo;
            return nome;
        },
        dayGroups: function () {
            let groups = [];
            let day = null;
            let count = -1;
            if (!this.messages) return groups;
            this.messages.forEach((message)=>{
                let  messageDay = this.giorno(message.locale_data_inviato);
                if (messageDay !== day) {
                    day = messageDay;
                    count +=1;
                    groups[count] = {
                        day: day,
                        messages: [],
                    }
                }
                groups[count].messages.push(message);
            });
            return groups;
        },

        classButtonToBottom: function () {
            if (this.showGoToBottom) {
                return "active";
            }
        },

        hasEvento: function () {
            if (!funzioni.deepProp(this.chat,'chat.evento')) return false;
            return true;
        },
    },

    beforeMount: async function () {
        const chatId = this.$route.params.id;
        const result = await this.loadMessages(chatId);
        if (!result.success) return;
        updateEditorSettings(result.data.type);
    },
    beforeDestroy: function () {
        clearInterval(this.watcher);
        this.watcher = null;
    },

    mounted() {
        this.init();
    },

    methods: {
        formatDate: funzioni.formatDate,
            shortDate: function (value) {
                if (!value) return ''
                let shortDate;
                let date = new Date(value);
                let now = new Date();
                if (now.toDateString() === date.toDateString()) {
                    shortDate = date.toLocaleTimeString();
                } else {
                    shortDate = date.toLocaleDateString();
                }

                return shortDate;
            },
        dismissModal () {
            this.$store.dispatch("messaggio/setDialog", false);
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },
        init: async function () {
            this.chatId = this.$route.params.id;
            this.timeOffset = this.getTimezoneOffset();
            await this.loadMessages(this.chatId);
            this.editorSettings = editorSettings;
            this.watchMessages();
        },

        getMessage: function (id) {
            return this.messages.find(el=>el.id==id);
        },

        getTimezoneOffset: function () {
            const d = new Date();
            return d.getTimezoneOffset() * 60 * 1000; // minuti di differenza dell'UTC
        },


        giorno: function (dateString) {
            let date = new Date(dateString);
            return INTL.format(date);
        },

        getDayMessage: function (message) {
            let date = new Date(message.timestamp_inviato*1000);
            return date.toDateString();
        },

        newDay: function (index) {
            if (index === 0) return true;
            let date1 = this.getDayMessage(this.messages[index]);
            let date2 = this.getDayMessage(this.messages[index - 1]);
            return date1 !== date2;
        },

        clearMessaggio: function () {
            this.messaggio = "";
        },


        updateLista: function () {
            this.lista.refresh = Date.now();
        }, 

        send: async function () {
            this.sending = true;
            if (!(this.messaggio && this.messaggio.length)) {
                return;
            }
            const chatId = this.chatId;
            const result = await messaggi.send(chatId, this.messaggio);
            this.sending = false;
            if (result.success) {
                this.clearMessaggio();
                await this.loadMessages(this.chatId);
                this.updateLista();
            }
        },

        debounceUpdate: function () {
            funzioni.debounce(this.update,500)()
        },

        update: function () {
            this.$emit('update', this.index, {...this.dettaglio });
        },


        watchMessages: function () {
            // TODO attivare watcher solo se riesco a disattivarlo una volta uscito dalla pagina messaggi
            this.watcher = setInterval(()=>{this.loadMessages(this.chatId)}, 10000); 
        },


        filterMilliseconds: function (ms) {
            let offset = this.timeOffset;
            if (!ms) return null;
            return ms - offset;
        },

        getMilliseconds: function (date) {
            return new Date(date).valueOf();
        },

        setLocaleDate: function (utc_date) {
            if (!utc_date) return null;
            const ms = this.filterMilliseconds(this.getMilliseconds(utc_date));
            return new Date(ms);
        },

        makeMessage: function (message) {
            return {
                ...message,
                locale_data_inviato: this.setLocaleDate(message.utc_data_inviato),
            }
        },

        makeMessages: function (messages) {
            let filteredMessages = messages.map(this.makeMessage);
            return filteredMessages;
        },


        loadMessages: async function (chatId) {
            const result = await messaggi.loadMessages(chatId);
            if (result.success) {
                this.messages = this.makeMessages(result.data.messaggi);
                this.nuovi = result.data.nuovi;
                this.chat = result.data;
            }
            return Promise.resolve(result);
        },
        findEventByCalendarioId: function (calendarioId) {
            if (!this.events) {
                return null;
            }
            return this.events.find((el)=>{
                return (el.data.calendario_id == calendarioId)
            });
        },
    }
}
export default component;
</script>

<style lang="scss">
.messaggi {
    --z-top: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    .messaggi-buttons {
        position: absolute;
        z-index: 1;
        top: 3px;
        right: 10px;
    }

    .messaggi-container {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        &__header {
            height: 56px;
            display: flex;
            align-items: center;
            padding: 5px 10px 5px 20px;
            gap: 10px;

            .icon-sport {
                font-size: 30px;
            }
        }
        .header-titolo {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .messaggi-lista {
        --background-chat: #fff;
        --background-my-message: var(--col-msp-chiaro);
        --background-message: var(--col-ui-evidenza-tint);
        background: var(--background-chat);
        overflow: auto;
        padding: 10px;
        position: relative;
        text-align: center;
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .messaggi-footer {
        flex-grow: 0;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
        height: 100%;
    }
    .msp-editor {
        height: 100%;
    }
    .messaggi-textarea {
        height: 100%;
    }
    .messaggio-data {
        z-index: var(--z-top);
        position: sticky;
        background: var(--col-msp);
        color: #fff;
        padding: 2px 10px;
        font-size: 0.8em;
        margin: 0 auto;
        text-align: center;
        display: inline-block;
        top: 0px;
        border-radius: 5px;
        pointer-event: none;
    }
}
</style>
