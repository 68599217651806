<template >
    <div class="commenti">

        <msp-app-bar title="Nuovi commenti"></msp-app-bar>

        <div class="chats-container" >
             <template v-if="notEmpty">
                 <v-text-field
                filled
                hide-details
                solo
                clearable
                v-model="cerca"
                label="Cerca per nome..."
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
                <div ref = "lista" class="chats-lista">
                    <CommentoItem
                        v-for="commento in filteredCommenti"
                        :key="commento.chat_id"
                        :commento="commento"
                        @select="openChat(commento)"
                    />
                <v-btn v-if="hasMore" block class="btn-more"  @click="loadMore"><span>Carica altri...</span></v-btn>
                </div>
             </template>

            <template v-else>
                <v-alert type="info">Non ci sono nuovi commenti.</v-alert>
            </template>
        </div>
        <v-dialog
            v-model="showModal"
            fullscreen
            hide-overlay
            transition="slide-x-transition"
            :return-value="'detail'"
        >
            <router-view></router-view>
        </v-dialog>
        <v-bottom-navigation 
            background-color="gray-3"
            dark
            v-if="showBottomNavigation" app class="commenti-tools">
            <v-btn class="btn-leggitutti"  v-if="nuovi" @click="readAll"><span>Segna {{filteredCommenti.length}} come già {{filteredCommenti.length == 1 ? 'letto' : 'letti'}}</span><i class="icon msp-icon-letto"></i></v-btn>
    </v-bottom-navigation>

        
    </div>
</template>


<script type="module">
import {  mapState } from 'vuex'
import mspAppBar from '@components/MspAppBar.vue';
import api from '@mixins/api.js';
import _messaggi from '@mixins/messaggi.js';
import env from '@mixins/environment.js'
import funzioni from '@src/js/msp/funzioni.js'
import CommentoItem from '@components/CommentoItem.vue'
const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
}
const locale = "it-IT";
const INTL = new Intl.DateTimeFormat(locale, options);


const component = {
    mixins: [
    env,
    ],
    components: {
        mspAppBar,
        CommentoItem,
    },
    props: [
    ],
    computed: {
        ...mapState(
            {
                dialog: (state)=>state.messaggio.dialog,
                    nuovi: (state)=>state.messaggio.countNewCommenti,
                    commenti: (state)=>state.messaggio.commenti,
            }
        ),

        filteredCommenti () {
            let commenti = funzioni.filter(this.commenti, this.cerca, ['utente.nome', 'utente.cognome']);
            return commenti;
        },
        showBottomNavigation: function () {
            return this.nuovi;
        },
        notEmpty: function () {
            return (this.commenti && this.commenti.length);
        },
        hasMore: function () {
            return this.nuovi > this.limit * this.page;
        },
    },

    watch: {
        nuovi: function () {
            this.reloadCommenti();
        },
        dialog: function (val) {
            this.showModal = val;
        },

        $route: {
            immediate: true,
            handler: function(newVal) {
                this.showModal = newVal.meta && newVal.meta.showModal;
            }
        }

    },
    data() {
        return {
            page: 1,
            limit: 20,
            cerca: "",
            showModal: false,
            timeOffset: 0,
            sending: false,
            watcher: null,
            chat: "",
        }
    },

    beforeDestroy: function () {
        clearInterval(this.watcher);
        this.watcher = null;
    },

    mounted() {
        this.init();
    },

    methods: {
        init: function () {
            this.timeOffset = this.getTimezoneOffset();
            this.reloadCommenti();
        },
            showNewMessages: function () {
                this.all = false;
            },
            readAll: async function () {
                //this.commenti.chat.nuovi.id

                let nuovi = this.filteredCommenti.filter(el=>el.chat.messaggio).map(el=>el.chat.messaggio.id);
                let ids = nuovi.join(",");
                let url = this.api_base_url+"/api/v2/messaggio.php?letto&ids="+ids;
                await api(url,"PUT", null)
                this.reloadCommenti();
            },
        openChat: async function (commento) {
            const calendarioId = commento.chat.chat.calendario_id;
            const newChat = await _messaggi.addChat('calendario', calendarioId);
            if (!newChat) return;
            const chatId = newChat.chat_id;
            this.$store.dispatch("messaggio/setDialog",true);
            this.$router.push("/chats/"+chatId);
        },


        getChat: function (id) {
            return this.chats.find(el=>el.id==id);
        },

        getTimezoneOffset: function () {
            const d = new Date();
            return d.getTimezoneOffset() * 60 * 1000; // minuti di differenza dell'UTC
        },

        setLetti: function (a_ids) {
            a_ids.forEach(this.setLetto);
        },

        setLetto: function (id) {
            let chat = this.getChat(id);
            if(!chat) return;
            if (chat.utc_data_letto) return;  
            if (this.letti.indexOf(id) === -1) {
                this.letti.push(+id);
            }
        },

        clearLetti: function (letti) {
            if (!(letti && letti.length)) return;
            letti = letti.map(id=>+id);
            const difference = this.letti.filter((el)=>{
                return letti.indexOf(el) === -1
            });
            this.letti = [...difference];
        },


        updateLetti: async function () {
            if (!(this.letti && this.letti.length)) return Promise.resolve({success: false});
            let a_ids = [...this.letti];
            let ids = a_ids.join(",");
            this.clearLetti(ids.split(","));
            _messaggi.setLetti(a_ids);
        },



        giorno: function (dateString) {
            let date = new Date(dateString);
            return INTL.format(date);
        },

        getDayChat: function (chat) {
            let date = new Date(chat.timestamp_inviato*1000);
            return date.toDateString();
        },

        newDay: function (index) {
            if (index === 0) return true;
            let date1 = this.getDayChat(this.chats[index]);
            let date2 = this.getDayChat(this.chats[index - 1]);
            return date1 !== date2;
        },

        clearChat: function () {
            this.chat = "";
        },

        setAllChatsRead: function () {
            let ids = this.chats && this.chats.map((el)=>(el.id));
            this.setLetti(ids);
            this.updateLetti();
        },


        send: async function () {
            this.sending = true;
            if (!(this.chat && this.chat.length)) {
                return;
            }
            let url = this.api_base_url+"/api/v2/chat.php";
            let data = {
                "testo": this.chat,
            };
            const result = await api(url, 'POST', data);
            this.sending = false;
            if (result.success) {
                this.clearChat();
                await this.loadCommenti();
            }
        },

        debounceUpdate: function () {
            funzioni.debounce(this.update,500)()
        },

        update: function () {
            this.$emit('update', this.index, {...this.dettaglio });
        },


        watchChats: function () {
            // TODO attivare watcher solo se riesco a disattivarlo una volta uscito dalla pagina chats
            this.watcher = setInterval(this.loadCommenti, 10000); 
        },


        filterMilliseconds: function (ms) {
            let offset = this.timeOffset;
            if (!ms) return null;
            return ms - offset;
        },

        getMilliseconds: function (date) {
            return new Date(date).valueOf();
        },

        setLocaleDate: function (utc_date) {
            if (!utc_date) return null;
            const ms = this.filterMilliseconds(this.getMilliseconds(utc_date));
            return new Date(ms);
        },


            reloadCommenti: function () {
                this.page = 1;
                this.$store.dispatch("messaggio/resetCommenti");
                this.loadCommenti();
            },
            loadMore: function () {
                this.page +=1 ;
                this.loadCommenti();
            },
            loadCommenti: async function () {
                const params = {
                    limit: this.limit,
                    page: this.page,
                };
                this.$store.dispatch("messaggio/countCommenti");
                this.$store.dispatch("messaggio/loadCommenti", params);
            },
    }
}
    export default component;
</script>

<style lang="scss">
.commenti {
    --z-top: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    .chats-buttons {
        position: absolute;
        z-index: 1;
        top: 3px;
        right: 10px;
    }

    .chats-container {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
    }

    .chats-lista {
        overflow: auto;
        padding: 10px;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .chats-footer {
        flex-basis: 125px;
        flex-grow: 0;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
    }
    .to-bottom {
        z-index: var(--z-top);
        position: absolute;
        top: 0;
        margin-top: -10px;
        transform: translatey(-100%);
        right: 20px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
    .chat-data {
        z-index: var(--z-top);
        position: sticky;
        background: var(--col-msp);
        color: #fff;
        padding: 2px 10px;
        font-size: 0.8em;
        margin: 0 auto;
        text-align: center;
        display: inline-block;
        top: 0px;
        border-radius: 5px;
        pointer-event: none;
    }
    .btn-leggitutti .icon {
        font-size: 1.5;
    }
}
</style>
