<template >
    <div class="messaggio" :data-id="messaggio.id" :class="classMessaggio">
        <msp-avatar v-if="hasAvatar" :class="classAvatar" class="messaggio__avatar" :utente="utente"></msp-avatar>
        <div class="messaggio__testo" v-html="messaggio.testo" />
        <span class="messaggio__ora">
            <span class='ora'>{{ora(messaggio)}}</span><span v-if='isRead'><i class="messaggio__letto msp-icon-letto"></i></span></span>
<svg
    class="fumetto"
    :style="styleFumetto"
   viewBox="0 0 26.458334 26.458334"
   >
    <path 
       d="M 2.7462769,0 H 24.565551 C 19.771754,4.308641 12.232087,9.785303 0,10.254826 6.4974235,7.210728 6.4451855,1.204393 2.7462769,0 Z"
 />
</svg>

            </div>

</template>

<script type="module">
import MspAvatar from "./MspAvatar.vue"
const options = {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
}
const locale = "it-IT";
const INTL = new Intl.DateTimeFormat(locale, options);

const component = {
    mixins: [
    ],
    components: {
        MspAvatar,
    },
    props: {
        'observer': IntersectionObserver,
        'messaggio': Object,
        'chat': Object,
        'hasAvatar': {
            type: Boolean,
            default: true, 
        },
    },
    computed: {
        styleFumetto: function () {
            return "";
        },
        classAvatar: function () {
            if (this.isSameUserAsPrevious) {
                return "prev";
            }
            return "";
        },
        index: function () {
            if (!this.chat) return null;
            if (!this.messaggio) return null;
            const i = this.chat.messaggi.findIndex((messaggio)=>messaggio.id == this.messaggio.id);
            return i;
        },
        prevIndex: function () {
            if (this.index < 1) return -1;
            return this.index - 1;
        },

        prevUser: function () {
            if (this.prevIndex < 0) return false;
            const prevUser = this.chat.messaggi[this.prevIndex].utente_id;
            return prevUser;
        },
        isSameUserAsPrevious: function () {
            if (this.prevIndex < 0) return false;
            return this.messaggio.utente_id == this.prevUser;
        },
        utente: function () {
            if (!this.chat) return null;
            if (!this.messaggio) return null;
            return this.chat.utenti.find(el=>el.id==this.messaggio.utente_id);
        },
        isChatCalendario: function () {
            if (!this.chat) return false;
            return this.chat.type == 'calendario';
        },
        isMe: function () {
            if (!this.messaggio) return false;
            if (!this.chat) return false;
            return (this.messaggio.utente_id == this.chat.me);
        },
        isRead: function () {
            if (!this.messaggio) return false;
            if (!this.chat) return false;
            if (!this.isMe) return false;
            if (!this.messaggio.destinatari) return false;
            const destinatari = this.messaggio.destinatari.filter(el=>el.utente_id != this.messaggio.utente_id && el.is_read == 1);
            return destinatari.length;
        },
        isNew: function () {
            if (!this.messaggio) return false;
            if (!this.chat) return false;
            let nuoviIds = this.chat.nuovi.map(el=>el.id);
            return nuoviIds.indexOf(this.messaggio.id) !== -1;
        },
        classMessaggio: function () {
            let c = "";
            if (this.isNew && !this.isMe) {
                c += " messaggio__nuovo";
            }
            if (this.isMe) {
                c += " messaggio__me";
            }
            if (this.isChatCalendario) {
                c += " messaggio__calendario";
            }
            if (!this.hasAvatar) {
                c += " no-avatar";
            }
            return c;
        },
    },
    data() {
        return {
            messages: [],
        }
    },
    mounted() {
        this.observeMe();
    },
    beforeDestroy() {
        this.unobserveMe();
    },
    methods: {
        observeMe: function () {
            if (!this.observer) return;
            if (this.isRead) return;
            this.observer.observe(this.$el);
        },
        unobserveMe: function () {
            if (!this.observer) return;
            this.observer.unobserve(this.$el);
        },
        ora: function (messaggio) {
            const date = new Date(messaggio.locale_data_inviato);
            return INTL.format(date);
        }
    }
}
    export default component;
</script>

<style lang="scss">
/* Stili definiti a livello globale.*/
</style>
